<template>
  <CModal
    :title="targetTariff.isTraining ? `${$t('lk.subscribe.modal.tariffTrainingBuy')}` : isChangingTariff ? `${$t('lk.subscribe.modal.tariffChange')}` : `${$t('lk.subscribe.modal.tariffBuy')}`"
    centered
    :show="showExplanation"
    :closeOnBackdrop="false"
    class="changeTariff"
    @update:show="$emit('updateShow', $event)"
  >
    <template v-if="!loadingPreBuy">
      <CRow class="mb-4" v-if="showCurrentTariff">
        <CCol col="7">
          <div class="hint  mb-1">
            {{ $t('lk.subscribe.modal.currentTariff') }}
          </div>
          <div
          class="value font-2xl text-uppercase"
          :style="{'color': trainingTariffColor ? trainingTariffColor : userCurrentTariff.color}">
            {{ currentTariffLabel }}
          </div>
        </CCol>
        <CCol col="5" class="text-right">
          <div class="hint  mb-1">
            {{ $t('lk.subscribe.modal.amountLeft') }}
          </div>
          <div class="value d-flex justify-content-end align-items-end">
            <div class="count">{{ futurePayment.amount_left }}</div>
            <div class="currency">USDT</div>
            <div class="days">({{ explanationData.days_left }} {{ $tc('lk.subscribe.modal.daysLeftDetail', explanationData.days_left) }})</div>
          </div>
        </CCol>
      </CRow>

      <CRow class="mb-4">
        <CCol col="7">
          <div class="hint mb-1">
            {{ $t('lk.subscribe.modal.newTariff') }}
          </div>
          <div class="value font-2xl text-uppercase d-flex flex-wrap align-items-end" :style="{'color': targetTariff.color}">
            <span class="tariff-label">
              {{ targetTariffLabel }}
            </span>
            <span v-if="!targetTariff.isTraining && !targetTariff.isGroup" class="text-lowercase font-sm mb-1">
              ({{ daysByMonthCount(targetTariff.month_count) }} {{ $tc('lk.subscribe.modal.daysLeftDetail', daysByMonthCount(targetTariff.month_count)) }})
            </span>
          </div>
        </CCol>
        <CCol col="5" class="text-right">
          <div class="hint mb-1">
            {{ $t('lk.subscribe.modal.overPayAmount') }}
          </div>
          <div class="value d-flex justify-content-end align-items-end">
            <div class="count">{{ explanationData.amount_needed }}</div>
            <div class="currency">USDT</div>
          </div>
        </CCol>
      </CRow>

      <template v-if="!freeActivationWithReturnMoney">
        <CRow class="mb-4">
          <CCol>
            <div class="balance d-flex justify-content-end">
              <div class="count mr-1"><span class="label">{{ $t('lk.subscribe.modal.balance') }}</span> {{ explanationData.balance }}</div>
              <div class="currency">usdt</div>
            </div>
            <CInput
              class="cinput"
              type="number"
              min="0"
              :max="explanationData.balance"
              :value="futurePayment.balance"
              @input="balanceInputHandler"
              :label="$t('lk.subscribe.modal.payWithBalance')"
              ref="balance"
              :isValid="(value) => this.isValidField(value, 'balance')"
              :invalidFeedback="`${$t('lk.subscribe.modal.maxAmountOnBalance')} ${explanationData.balance} usdt`"
              placeholder=""
            >
              <template #append-content><span class="input-action" @click="fillBalance">{{ $t('lk.subscribe.modal.enterFullSum') }}</span>
              </template>
            </CInput>
          </CCol>
        </CRow>

        <CRow class="mb-4">
          <CCol>
            <CRow class="mb-2">
              <CCol col="7">
                <CInputCheckbox
                  :checked.sync="futurePayment.useRefBalance"
                  custom
                  :label="$t('lk.subscribe.modal.payWithRef')"
                />
              </CCol>
              <CCol col="5" class="d-flex justify-content-end">
                <div class="count mr-1"><span class="label">{{ $t('lk.subscribe.modal.balance') }}</span> {{ explanationData.ref_balance }}</div>
                <div class="currency">usdt</div>
              </CCol>
            </CRow>
            <CInput
              v-if="futurePayment.useRefBalance"
              class="cinput"
              type="number"
              min="0"
              :max="explanationData.ref_balance"
              @input="refBalanceInputHandler"
              ref="refBalance"
              :isValid="(value) => this.isValidField(value, 'ref_balance')"
              :invalidFeedback="`${$t('lk.subscribe.modal.maxAmountOnBalance')} ${explanationData.ref_balance} usdt`"
              :value="futurePayment.ref_balance"
              placeholder=""
            >
              <template #append-content><span class="input-action" @click="fillRefBalance">{{ $t('lk.subscribe.modal.enterFullSum') }}</span>
              </template>
              <template #description><span
                class="info mt-2">{{ $t('lk.subscribe.modal.payRefHint') }}</span></template>
            </CInput>
          </CCol>
        </CRow>
      </template>

      <CRow>
        <template v-if="explanationData.invoiceNeeded">
          <CCol class="summ">
            <span class="info">
              {{ $t('lk.subscribe.modal.totalSum') }}
            </span>
            <span class="cost font-2xl mr-1">{{ merchantCost }}</span>
            <span class="currency">USDT</span>
          </CCol>
        </template>
        <template v-else>
          <CCol class="summ" v-if="freeActivationWithReturnMoney">
            <span class="info">
              {{ $t('lk.subscribe.modal.balanceReturn') }}
            </span>
            <span class="cost font-2xl mr-1">{{ futurePayment.returnMoney }}</span>
            <span class="currency">USDT</span>
          </CCol>
          <CCol class="summ" v-else>
            <span class="info">
              {{ $t('lk.subscribe.modal.totalWithdrawalCost') }}
            </span>
            <span class="cost font-2xl mr-1">{{ withdrawalCost }}</span>
            <span class="currency">USDT</span>
          </CCol>
        </template>
      </CRow>
      <template v-if="explanationData.invoiceNeeded">
        <div class="hint  mb-1 mt-3">
            {{ $t('lk.subscribe.modal.agreeLabel') }}
        </div>
        <CButtonGroup class="d-flex">
          <CButton
            v-for="(item, i) in agreeOptions"
            :key="i"
            color="dark"
            class="cbutton"
            :variant="isAgree === item?.value? '' : 'outline'"
            @click="isAgree = item.value">
            {{ item.label }}
          </CButton>
        </CButtonGroup>
      </template>
    </template>

    <CSpinner
      v-if="loadingPreBuy"
      color="primary"
      size="lg"
    />

    <template v-slot:footer>
      <div class="w-100">
        <template v-if="explanationData.invoiceNeeded">
          <CLoadingButtonCustom
            color="success"
            class="mr-2 w-100 p-2"
            :loading="loadingBuyOperation"
            :disabled="!isFormValid || loadingBuyOperation"
            @click.native="buyBonuses"
          >
            {{ $t('lk.subscribe.modal.buyButton') }}
          </CLoadingButtonCustom>
        </template>
        <template v-else>
          <CLoadingButtonCustom
            v-if="freeActivationWithReturnMoney"
            color="success"
            class="mr-2 w-100 p-2"
            :loading="loadingBuyOperation"
            :disabled="loadingBuyOperation"
            @click.native="payTariff"
          >
            {{ $t('lk.subscribe.modal.changeTariffButton') }}
          </CLoadingButtonCustom>
          <CLoadingButtonCustom
            v-else
            color="success"
            class="mr-2 w-100 p-2"
            :loading="loadingBuyOperation"
            :disabled="!isFormValid || (withdrawalCost !== explanationData.amount_result) || loadingBuyOperation"
            @click.native="payTariff"
          >
            {{ $t('lk.subscribe.modal.payButton') }}
          </CLoadingButtonCustom>
        </template>
      </div>
    </template>
  </CModal>
</template>
<script>
import axios from "axios";

export default {
  name: 'ChangeTariff',
  props: {
    showExplanation: {},
    targetTariff: {},
  },
  data() {
    return {
      agreeOptions: [
        {
          label: this.$t('lk.training.addToNewModal.yes'),
          value: '1'
        },
        {
          label: this.$t('lk.training.addToNewModal.no'),
          value: '0'
        }
      ],
      isAgree: '1',
      loadingPreBuy: true,
      loadingBuyOperation: false,
      explanationData: {},
      MINIMAL_INVOICE: 12,
      futurePayment: {
        balance: 0,
        ref_balance: 0,
        returnMoney: false,
        amount_left: 0,
        useRefBalance: false
      }
    }
  },
  computed: {
    hasMentor(){
      return this.$store.state.user.userData?.events_users?.[0]?.has_mentor
    },
    currentTariffLabel(){
      if(this.targetTariff.isTraining && this.trainingTariff) return this.hasMentor ? `${this.trainingTariff} + MENTORSHIP` : this.trainingTariff
      return this.userCurrentTariff.name[this.locale]
    },
    targetTariffLabel(){
      if(this.targetTariff.isTraining){
        if(this.targetTariff.tariff_id === 'mentorship_edu'){
          return `${this.currentTariffEdu?.name['en']} + MENTORSHIP`
        }
        if(this.hasMentor && !this.targetTariff.isIndependent){
          return `${this.targetTariff.title[this.locale]} + MENTORSHIP`
        }
        return this.targetTariff.title[this.locale]
      }
      return this.targetTariff.title?.[this.locale] || this.targetTariff.title
    },
    currentTariffEdu(){
      return this.$store.state.user.userData?.events_users?.[0]?.tariff
    },
    showCurrentTariff(){
      if(this.targetTariff.isTraining) return !!this.currentTariffEdu
      return this.futurePayment.amount_left > 0
    },
    locale() {
      return this.$root.$i18n.locale
    },
    // будет реализована покупка тарифа с возвратом денег на баланс
    freeActivationWithReturnMoney() {
      return this.futurePayment.returnMoney !== false
    },
    isFormValid() {
      if (this.futurePayment.useRefBalance) {
        return this.isValidField(this.futurePayment.balance, 'balance') && this.isValidField(this.futurePayment.ref_balance, 'ref_balance')
      } else {
        return this.isValidField(this.futurePayment.balance, 'balance')
      }
    },
    userCurrentTariff() {
      return this.$store.state.user.userData.tariff || {id: ''}
    },
    trainingTariff() {
      if (!this.currentTariffEdu) return false
      return this.currentTariffEdu?.id === 'standard_edu' ? 'STANDART' : 'ALL-IN'
    },
    trainingTariffColor() {
      if(!this.trainingTariff) return false
      return this.trainingTariff === 'STANDART' ? '#3686F6' : '#FCB81C'
    },
    isChangingTariff() {
      if(this.targetTariff.isTraining) return false
      return this.userCurrentTariff.id !== this.targetTariff.tariff_id
    },
    // сумма для пополнения счета через мерчанта
    merchantCost() {
      let cost = this.saveParseInt(this.explanationData.amount_needed)
      if (this.isValidField(this.futurePayment.balance, 'balance')) {
        cost -= this.saveParseInt(this.futurePayment.balance)
      }
      if (this.futurePayment.useRefBalance && this.isValidField(this.futurePayment.ref_balance, 'ref_balance')) {
        cost -= this.saveParseInt(this.futurePayment.ref_balance)
      }
      return cost
    },
    // сумма списания с баланса и реф счета юзера
    withdrawalCost() {
      let cost = this.saveParseInt(this.futurePayment.amount_left)
      if (this.isValidField(this.futurePayment.balance, 'balance')) {
        cost += this.saveParseInt(this.futurePayment.balance)
      }
      if (this.futurePayment.useRefBalance && this.isValidField(this.futurePayment.ref_balance, 'ref_balance')) {
        cost += this.saveParseInt(this.futurePayment.ref_balance)
      }
      return cost
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    refBalanceInputHandler(value){
      if(+value > +this.explanationData.ref_balance){
        this.futurePayment.ref_balance = this.saveParseInt(this.explanationData.ref_balance)
        this.$refs.refBalance.state = this.saveParseInt(this.explanationData.ref_balance)
      }else{
        this.futurePayment.ref_balance = value
        this.$refs.refBalance.state = value
      }
    },
    balanceInputHandler(value){
      if(+value > +this.explanationData.balance){
        this.futurePayment.balance = this.saveParseInt(this.explanationData.balance)
        this.$refs.balance.state = this.saveParseInt(this.explanationData.balance)
      }else{
        this.futurePayment.balance = value
        this.$refs.balance.state = value
      }
    },
    saveParseInt(string) {
      return Math.floor(string)
    },
    fillBalance() {
      const targetSum = this.explanationData.amount_needed // сумма которую нужно списать для покупки
      this.balanceInputHandler(targetSum)
      // if (targetSum < this.explanationData.balance) { // проверяем есть ли данная сумма на счету юзера. если есть достаточно, то заносим её
      //   this.futurePayment.balance = targetSum
      // } else {
      //   this.futurePayment.balance = parseInt(this.explanationData.balance) // если нет, то заносим все, что есть на балансе
      // }

      // если в реф бонусах уже что-то указано и они включены для использования - уменьшаем его размер, чтобы максимально оплатить с выбранного счета
      if (this.futurePayment.useRefBalance && this.futurePayment.ref_balance > 0) {
        const diff = targetSum - this.saveParseInt(this.futurePayment.balance) - this.saveParseInt(this.futurePayment.ref_balance)
        if (diff < 0) {
          this.futurePayment.ref_balance -= Math.abs(diff)
          this.$refs.refBalance.state = this.futurePayment.ref_balance
        }
      }
    },
    fillRefBalance() {
      const possibleRefSpend = this.explanationData.amount_needed // высчитываем сколько необходимо списать с реф баланса
      this.refBalanceInputHandler(possibleRefSpend)
      // if (possibleRefSpend < this.explanationData.ref_balance) { // если столько есть на реф счету, то заносим эту сумму
      //   this.futurePayment.ref_balance = possibleRefSpend
      // } else {
      //   this.futurePayment.ref_balance = Math.floor(this.explanationData.ref_balance) // если нет, то заносим все, что есть
      // }

      // если в основном балансе что-то указано, то уменьшаем его размер, чтобы максимально оплатить с выбранного счета
      const diff = this.explanationData.amount_needed - this.saveParseInt(this.futurePayment.balance) - this.saveParseInt(this.futurePayment.ref_balance)
      if (diff < 0) {
        this.futurePayment.balance -= Math.abs(diff)
        this.$refs.balance.state = this.futurePayment.balance
      }
    },
    payTariff() {
      if (
        this.targetTariff &&
        this.targetTariff.tariff_id &&
        (this.targetTariff.isTraining || this.targetTariff.month_count || this.targetTariff.isGroup)
      ) {
        this.loadingBuyOperation = true
        const fd = new FormData()
        if(this.targetTariff.isTraining) {
          fd.append('tariff', this.targetTariff.tariff_id)
          if (this.targetTariff.tariff_id === 'mentorship_edu') {
            fd.append('curator', this.targetTariff.curator)
          }
        } else {
          fd.append('tariff_id', this.targetTariff.tariff_id)
          if (this.targetTariff.month_count) {
            fd.append('month_count', this.targetTariff.month_count)
          }
          // fd.append('freeActivationWithReturnMoney', this.freeActivationWithReturnMoney ? '1' : '0')
        }
        fd.append('balance', this.futurePayment.balance)
        if (this.futurePayment.useRefBalance || this.targetTariff.isGroup) {
          fd.append('referral_balance', this.futurePayment.ref_balance || '0')
        }

        let linkForRequest

        switch (true) {
          case this.targetTariff.isTraining:
            linkForRequest = `api/v2/events/${this.targetTariff.courseId}/buy`
            break
          case this.targetTariff.isGroup:
            linkForRequest = `api/v2/tariffs/group/buy`
            break
          default:
            linkForRequest = `/api/v2/tariffs/buy`
            break
        }

        axios.post(linkForRequest, fd)
          .then(({data}) => {
            const {tariff_changed, status, errors} = data
            const requestSuccess = this.targetTariff.isTraining ? status : tariff_changed
            if (requestSuccess) {
              this.$toastr.success(this.$t('lk.subscribe.changeTariffSuccess'))
              this.showConfirm = false
              // this.waitText = this.$t('lk.subscribe.reloadingStatus')
              this.$store.dispatch('user/getCurrent')
                .then(({status}) => {
                  if (status < 300) {
                    this.$toastr.success(this.$t('lk.subscribe.reloadedStatus'))
                    this.$root.$emit('dropdownMenuUpdate')
                    // this.waitText = ''
                  }
                })
              this.$emit('updateShow', false)
              this.$emit('tariffChanged')
              if(this.targetTariff.isTraining && !this.$store.state.user.userData.events) {
                this.$emit('firstBuyTraining')
              }
            }else{
              if(errors){
                const errorsList = Array.isArray(errors) ? errors : [errors]
                errorsList.forEach(item=>{
                  this.$toastr.error(item)
                })
                return
              }
              this.$toastr.error(this.$t('errors.buyError'))
            }
          })
          .catch((e) => {
            console.error('error pay tariff', e)
          })
          .finally(() => {
            this.$emit('updatePreview')
            this.loadingBuyOperation = false
          })
      }
    },
    buyBonuses() {
      if(!this.isAgree){
        this.$toastr.warning(`${this.$t('general.answerQuestion')}: ${this.$t('lk.subscribe.modal.agreeLabel')}`)
        return
      }
      this.loadingBuyOperation = true
      axios.post('/api/v2/bonuses/buy', {
        amount: this.merchantCost,
        agree: this.isAgree,
        tariff_id: this.targetTariff.tariff_id,
        month_count: this.targetTariff.month_count,
        balance: this.futurePayment.balance,
        referral_balance: this.futurePayment.ref_balance,
        is_training: this.targetTariff.isTraining ? 1 : 0,
        ...(this.targetTariff.tariff_id === 'mentorship_edu' && {
          params: {
            curator: this.targetTariff.curator
          }
        })
      })
        .then(({data}) => {
          const {payment_url, payment_id} = data
          if (payment_url && payment_id) { // процесс оплаты
            this.$toastr.success(this.$t('lk.subscribe.switchToPayPage'))
            this.waitPay = true
            setTimeout(() => {
              const resWindowOpen = window.open(payment_url)
              if (!resWindowOpen) {
                this.paymentUrl = payment_url
                this.$emit('errorPopup', {
                  'type': 'block',
                  'message': payment_url
                })
              }
              this.$emit('updateShow', false)
            }, 5000)
          }
        })
        .catch((e) => {
          console.error(`Ошибка покупки бонусов ${e}`)
          this.$toastr.error(this.$t('lk.subscribe.modal.errorBuyBonuses'))
        })
        .finally(() => {
          this.$emit('updatePreview')
          this.loadingBuyOperation = false
        })
    },
    // валидное поле имеет значение больше 0 и меньше баланса на счете (основной или реф)
    isValidField(value, field) {
      return +value >= 0 && +value <= this.explanationData[field]
    },
    init() {
      this.$store.dispatch('user/getCurrent')
        .then(() => {
          this.$root.$emit('dropdownMenuUpdate')
        })
      // this.explanationData.changingTariff = this.userTariffId !== obj.tariff_id

      let linkForRequest
      let params = {}

      switch (true) {
        case this.targetTariff.isTraining:
          linkForRequest = `api/v2/events/${this.targetTariff.courseId}/pre-buy`
          params = {
            tariff: this.targetTariff.tariff_id
          }
          break
        case this.targetTariff.isGroup:
          linkForRequest = `api/v2/tariffs/group/pre-buy`
          params = {
            tariff_id: this.targetTariff.tariff_id
          }
          break
        default:
          linkForRequest = 'api/v2/tariffs/pre-buy'
          params = {
            tariff_id: this.targetTariff.tariff_id,
            month_count: this.targetTariff.month_count
          }
          break
      }

      axios.post(linkForRequest, params)
        .then(({data}) => {
          let {amount_left, amount_result, balance, days_left, invoiceNeeded, ref_balance, amount_needed, errors} = data
          if(balance !== undefined){
            this.futurePayment.amount_left = amount_left
            if (amount_needed < 0) {
              this.futurePayment.returnMoney = Math.abs(amount_needed)
              amount_needed = 0
            }
            balance = balance?.toFixed(2) || 0
            ref_balance = ref_balance?.toFixed(2)
            this.explanationData = {
              days_left,
              amount_result,
              amount_needed,
              balance,
              ref_balance,
              invoiceNeeded,
            }
          }else{
              if(errors){
                const errorsList = Array.isArray(errors) ? errors : [errors]
                errorsList.forEach(item=>{
                  this.$toastr.error(item)
                })
                this.$emit('updateShow', false)
                return
              }
              this.$toastr.error(this.$t('errors.buyError'))
              this.$emit('updateShow', false)
            }

        })
        .catch(() => {
          this.$emit('updateShow', false)
        })
        .finally(() => {
          this.$emit('updatePreview')
          this.loadingPreBuy = false
        })
    },
    daysByMonthCount(monthCount){
      if(monthCount === 12){
        return 365
      }
      return monthCount * 30
    }
  }
}
</script>

<style lang="scss" scoped>
.changeTariff{
  /deep/ .modal-header{
    display: flex !important;
  }
}
.value {
  font-size: 28px;
  gap: 5px;
  line-height: 1;

  .currency {
    font-size: 17px;
    margin-bottom: 1px;
    color: var(--success);
  }

  .days {
    font-size: 14px;
    margin-bottom: 3px;
  }
}

.balance {
  margin-bottom: -21px;

  .count {
    .label {
      opacity: 0.6;
    }
  }
}

.currency {
  color: var(--success);
}

.input-action {
  color: var(--success);
  cursor: pointer;
  border-bottom: 1px dashed var(--success);
}

.summ {
  .info {
    opacity: 0.6;
    font-size: 14px;
  }
}

.info {
  font-size: 12px;
}

.cinput {
  /deep/ {
    input[type="number"] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
  }
}
</style>
